import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    video: {
        padding: theme.spacing(0.5),
        margin: theme.spacing(3, 2),
        // maxWidth: '50%'
    },
    description: {
        whiteSpace: 'pre-line'
    }
}));

const Video = (props) => {
    const { title, description, src, isYoutube } = props;

    const classes = useStyles();

    const showDescription = Boolean(description);
    const showTitle = Boolean(title);

    let media = null;

    if (isYoutube) {
        media = <iframe
            width="100%"
            height="309"
            src={src}
            title={title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />;
    } else {
        media = <CardMedia
            component="video"
            title={title}
            controls
        >
            <source src={src} type="video/mp4" />
        </CardMedia>;
    }

    return (
        <Card className={classes.video}>
            {media}
            <CardContent>
                {showTitle &&
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                }
                {showDescription &&
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        className={classes.description}
                    >
                        {description}
                    </Typography>
                }
            </CardContent>
        </Card>
    );
};

export default Video;