import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container } from '@material-ui/core';

import { LanguageContext } from '../../Context/';

const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1,
        overflow: 'auto'
    },
    title: {
        marginBottom: theme.spacing()
    },
    address: {
        whiteSpace: 'pre-line'
    },
    subtitle: {
        marginTop: theme.spacing(2)
    },
    paragraph: {
        marginBottom: theme.spacing()
    }
}));

const LegalNotice = () => {
    const { translate } = useContext(LanguageContext);
    const classes = useStyles();

    const title = translate("Legal notice");
    const bandName = "BlackBasement GbR";
    const address = "Theodorstraße 9\n30159 Hannover";

    const firstSubtitle = translate("Represented by:");
    const name = "Marc Dohmeyer";
    const email = "info@blackbasement.de";

    const secondSubtitle = translate("Liability for contents:");
    const liabilityText1 = translate("legalNotice_liabilityText1");
    const liabilityText2 = translate("legalNotice_liabilityText2");

    const thirdSubtitle = translate("Liability for hyperlinks:");
    const liabilityLinksText1 = translate("legalNotice_liabilityLinksText1");
    const liabilityLinksText2 = translate("legalNotice_liabilityLinksText2");

    const fourthSubtitle = translate("Copyright");
    const copyrightText1 = translate("legalNotice_CopyrightText1");
    const copyrightText2 = translate("legalNotice_CopyrightText2");

    return (
        <Container className={classes.container} maxWidth="sm">
            <Typography className={classes.title} variant="h3">{title}</Typography>
            <Typography>{bandName}</Typography>
            <Typography className={classes.address}>{address}</Typography>

            <Typography className={classes.smallSubtitle} variant="subtitle1">{firstSubtitle}</Typography>
            <Typography>{name}</Typography>
            <Typography>{email}</Typography>

            <Typography className={classes.subtitle} variant="h5">{secondSubtitle}</Typography>
            <Typography className={classes.paragraph}>{liabilityText1}</Typography>
            <Typography className={classes.paragraph}>{liabilityText2}</Typography>

            <Typography className={classes.subtitle} variant="h5">{thirdSubtitle}</Typography>
            <Typography className={classes.paragraph}>{liabilityLinksText1}</Typography>
            <Typography className={classes.paragraph}>{liabilityLinksText2}</Typography>

            <Typography className={classes.subtitle} variant="h5">{fourthSubtitle}</Typography>
            <Typography className={classes.paragraph}>{copyrightText1}</Typography>
            <Typography className={classes.paragraph}>{copyrightText2}</Typography>
        </Container>
    );
};

export default LegalNotice;