import React, { Fragment, useContext, useState } from 'react';

import NavData from '../../Assets/NavigationData.json';

import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer, IconButton, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { useWidth } from '../../Hooks/';
import { LanguageContext } from '../../Context/';

import LanguageSelector from '../LanguageSelector';
import LargeNav from './LargeNav';

const useStyles = makeStyles((theme) => ({
    button: {
        color: theme.palette.common.black
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    drawer: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.black
    },
    listItem: {
        padding: theme.spacing(1, 3, 1, 4)
    },
    divider: {
        flex: 1
    },
    lngSelectorWrapper: {
        padding: theme.spacing(1, 2),
        backgroundColor: theme.palette.background.paper

        // "& > *": {
        //     color: theme.palette.common.black
        // }
    }
}));

const NavigateToSection = (sectionId) => {
    const element = window.document.getElementById(sectionId);

    try {
        element.scrollIntoView({
            behavior: "smooth"
        });
    } catch (error) {
        element.scrollIntoView();
    }
};

const ResponsiveToolbar = () => {
    const width = useWidth();
    const classes = useStyles();
    const { translate } = useContext(LanguageContext);

    const [isOpen, setIsOpen] = useState(false);

    if (width === "md" || width === "lg" || width === "xl") {
        return <LargeNav
            navData={NavData}
            onNavigate={NavigateToSection}
        />;
    }

    const handleClick = () => {
        if (isOpen) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const navDataWithClickFunc = NavData.map(({ sectionId, ...others }) => ({
        ...others,
        onClick: () => {
            handleClose();
            window.requestAnimationFrame(() => NavigateToSection(sectionId));
        }
    }));

    return (
        <Fragment>
            <IconButton className={classes.button} onClick={handleClick}>
                <MenuIcon />
            </IconButton>
            <Drawer
                anchor="left"
                classes={{ paper: classes.drawer }}
                open={isOpen}
                onClose={handleClose}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleClose} className={classes.button}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    {navDataWithClickFunc.map(({ label, ...others }, index) => (
                        <ListItem
                            key={index}
                            button
                            {...others}
                            className={classes.listItem}
                            color="inherit"
                        >
                            <ListItemText primary={translate(label)} />
                        </ListItem>
                    ))}
                </List>
                <div className={classes.divider} />
                <div className={classes.lngSelectorWrapper}>
                    <LanguageSelector />
                </div>
            </Drawer>
        </Fragment>
    );
};

export default ResponsiveToolbar;