import React, { useContext, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Link, Typography } from '@material-ui/core';

import { LanguageContext } from '../../Context/';

import { useWidth } from '../../Hooks';

import ContainerCollapse from './ContainerCollapse';
import LegalNotice from './LegalNotice';
import PrivacyNotice from './PrivacyNotice';
import LanguageSelector from '../LanguageSelector';
import { SCROLL_REF_ID } from '../Main';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3)
    },
    linkContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-evenly'
    },
    languageSelector: {
        position: 'absolute',
        right: theme.spacing(),
        bottom: theme.spacing()
    }
}));

const Footer = () => {
    const width = useWidth();
    const classes = useStyles();
    const { translate } = useContext(LanguageContext);

    const [opened, setOpend] = useState(null);

    const handleOpen = (val) => {
        setOpend(val);
        window.requestAnimationFrame(() => {
            const scrollElement = window.document.getElementById(SCROLL_REF_ID);
            try {
                scrollElement.lastChild.scrollIntoView({
                    behavior: "smooth",
                    block: "end"
                });
            } catch (error) {
                scrollElement.lastChild.scrollIntoView(false);
            }
        });
    };

    const handleLegalNoticeOpen = () => {
        handleOpen("legal");
    };

    const handlePrivacyNoticeOpen = () => {
        handleOpen("privacy");
    };

    const handleClose = () => {
        setOpend(null);
    };

    const renderLngSelector = width === "md" || width === "lg" || width === "xl";

    return (
        <div className={classes.container}>
            <div className={classes.linkContainer}>
                <Typography>
                    <Link onClick={handleLegalNoticeOpen}>
                        {translate("Legal notice")}
                    </Link>
                </Typography>
                <Typography>
                    <Link onClick={handlePrivacyNoticeOpen}>
                        {translate("Privacy notice")}
                    </Link>
                </Typography>
            </div>
            <ContainerCollapse
                openCollapse={Boolean(opened)}
                onClose={handleClose}
            >
                {opened === "legal" ?
                    <LegalNotice />
                    : <PrivacyNotice />
                }
            </ContainerCollapse>
            {renderLngSelector &&
                <LanguageSelector className={classes.languageSelector} />}
        </div>
    );
};

export default Footer;