const GetInitialLanguage = (availableLanguages) => {
    const initialLanguage = navigator.language || navigator.userLanguage;

    if (Array.isArray(availableLanguages)) {
        const isInAvailableLanguages = availableLanguages.includes(initialLanguage);

        if (isInAvailableLanguages) {

            return initialLanguage;
        }
    }

    return "en-EN";
};

export default GetInitialLanguage;