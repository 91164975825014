import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';

import { LanguageContext } from '../../../Context/';

import { useWidth } from '../../../Hooks/';

import IntroductionImage from '../../../Assets/Introduction.jpg';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        minHeight: 'calc(100vh - 88px)',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    subtitle: {
        marginBottom: theme.spacing(2)
    },
    paragraph: {
        whiteSpace: 'pre-line'
    },
    gridItem: {
        display: 'flex'
    },
    textContainer: {
        alignSelf: 'center'
    },
    imageWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {
        float: 'right',
        width: '100%',
        maxWidth: 400
    }
}));

const SectionId = "Introduction";
const Title = "Black Basement";
const SubTitle = "section_intro_subtitle";

const paragraph1 = "section_intro_para_1";
const paragraph2 = "section_intro_para_2";
const paragraph3 = "section_intro_para_3";

const Introduction = () => {
    const width = useWidth();
    const classes = useStyles();
    const { translate } = useContext(LanguageContext);

    const shouldDisableGutters = width === "lg" || width === "xl";

    return (
        <Container className={classes.container} id={SectionId} disableGutters={shouldDisableGutters}>
            <Grid container>
                <Grid item xs={12} sm={6} className={classes.gridItem}>
                    <div className={classes.textContainer}>
                        <Typography variant="h3" gutterBottom={false}>
                            {translate(Title)}
                        </Typography>
                        <Typography className={classes.subtitle} color="textSecondary" variant="subtitle1">
                            {translate(SubTitle)}
                        </Typography>
                        <Typography className={classes.paragraph} /* variant="body1" */ gutterBottom>
                            {translate(paragraph1)}
                        </Typography>
                        <Typography className={classes.paragraph} /* variant="body1" */ gutterBottom>
                            {translate(paragraph2)}
                        </Typography>
                        <Typography className={classes.paragraph} /* variant="body1" */ gutterBottom>
                            {translate(paragraph3)}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={classes.imageWrapper}>
                        <img className={classes.image} src={IntroductionImage} />
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Introduction;


// <title> Black Basement
// <subtitle> Rock from Hannover

// Nach der Gründung 2020, erfand das Trio, aus Marc Dohmeyer (Vocals/Bass), Henning Fahß (Drums) und Dennis Glinke (Gitarre/Vocals), kurzerhand ein eigenes Genre…Bigfootrock! Die aufstrebenden Musiker aus Hannover vereinen in Ihrer Musik nicht nur Kreativität mit der immer wieder erscheinenden Frage ”schon mal drüber nachgedacht?”, sondern zeichnen sich vor allem durch verzahnte Instrumentalistik, fette Gitarrenriffs und verschrobene Thematiken aus.

// Die Band liefert zeitgemäßen Freigeist-Rock mit der gewissen Priese Verschwörungstheorie.\nBedingt durch den einzigartigen Vibe dieses Trios, erwartet den Hörer ein individueller Sound mit faszinierenden Storys.

// Im Februar veröffentlichten Black Basement die erste Single ”7885” und die zweite Single „Settle Down“ im April 2021. Mit Hinblick auf ein, im späten Sommer, folgendes Album, kann der Hörer sich auf weitere Singles im Laufe 2021 freuen und mitfiebern.