import { createContext } from 'react';

const NavigateToSection = (sectionId) => {
    const element = window.document.getElementById(sectionId);

    try {
        element.scrollIntoView({
            behavior: "smooth"
        });
    } catch (error) {
        element.scrollIntoView();
    }
};

export default createContext({
    currentIndex: 0,
    NavigateToSection
});