import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(0, 1)
        // color: theme.palette.common.black,
    }
}));

const BaseButton = (props) => {
    const { url, ...others } = props;

    const classes = useStyles();

    if (!url) {
        return null;
    }

    const handleClick = () => {
        window.open(url);
    };

    return (
        <Fab color="primary" className={classes.button} onClick={handleClick} {...others} />
    );
};

export default BaseButton;