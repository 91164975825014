import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Avatar, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    member: {
        marginBottom: theme.spacing(4)
    },
    avatar: {
        width: '120px',
        height: '120px',
        margin: 'auto'
    },
    description: {
        padding: theme.spacing()
    }
}));

const Member = (props) => {
    const classes = useStyles();
    const { name, avatar, description } = props;

    return (
        <Grid item sm={4} className={classes.member}>
            <Avatar className={classes.avatar} src={avatar} />
            <Typography align="center" variant="h5">{name}</Typography>
            <Typography align="center" className={classes.description}>{description}</Typography>
        </Grid>
    );
};

export default Member;