export default {
    "legalNotice_liabilityText1": "As a service provider, we are responsible for our own content on these pages in accordance with § 7 Para. 1 of the TMG. general laws. However, according to §§ 8 to 10 TMG, we as a service provider are not to monitor transmitted or stored information from third parties or to investigate circumstances that indicate illegal activity.",
    "legalNotice_liabilityText2": "Obligations to remove or block the use of information in accordance with general laws remain unaffected by this. However, any liability in this regard is only possible from the time of knowledge of a concrete violation of the law. If we become aware of corresponding infringements, we will remove this content immediately.",
    "legalNotice_liabilityLinksText1": "Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore, we cannot assume any liability for these external contents. For the contents of the linked pages is always the responsibility of the respective provider or operator of the pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognizable at the time of linking.",
    "legalNotice_liabilityLinksText2": "However, a permanent control of the linked pages is not reasonable without concrete evidence of an infringement. If we become aware of any infringements of the law, we will remove such links immediately.",
    "legalNotice_CopyrightText1": "The contents and works on these pages created by the site operators are subject to German copyright law. The duplication, processing, distribution and any kind of exploitation outside the of the copyright require the written consent of the respective author or creator. Downloads and copies of this site are only permitted for private, non-commercial use.",
    "legalNotice_CopyrightText2": "Insofar as the content on this page was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is identified as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly and we will remove such content immediately.",
    "privacyNotice_generalText": "The following notices provide a simple overview of what happens to your personal data when you visit this website. Personal data is any data that can be used to identify you personally. For detailed information on the subject of data protection, please refer to our privacy policy listed below this text.",
    "privacyNotice_whoIsText": "Data processing on this website is carried out by the website operator. You can find the contact details of the website operator in the section \"Information about the responsible party\" in this data protection declaration.",
    "privacyNotice_howText1": "On the one hand, your data is collected by you providing it to us. This can be, for example, data that you enter in a contact form.",
    "privacyNotice_howText2": "Other data is collected automatically or after your consent when you visit the website by our IT systems. This is mainly technical data (e.g. Internet browser, operating system or time of page view). The collection of this data takes place automatically as soon as you enter this website.",
    "privacyNotice_whatText": "Some of the data is collected to ensure error-free provision of the website. Other data may be used to analyze your user behavior.",
    "privacyNotice_rightsText": "You have the right at any time to receive information free of charge about the origin, recipient and purpose of your stored personal data. You also have a right to request the correction or deletion of this data. If you have given your consent to data processing, you can revoke this consent at any time for the future. You also have the right to request the restriction of the processing of your personal data under certain circumstances. Furthermore, you have the right to lodge a complaint with the competent supervisory authority.",
    "privacyNotice_rightsText2": "You can contact us at any time with regard to this and other questions on the subject of data protection.",
    "privacyNotice_hostingText": "This website is hosted by an external service provider (hoster). The personal data collected on this website is stored on the hoster's servers. This may include IP addresses, contact requests, meta and communication data, contract data, contact data, names, website accesses and other data generated via a website.",
    "privacyNotice_hostingText2": "The hoster is used for the purpose of fulfilling contracts with our potential and existing customers (Art. 6 para. 1 lit. b DSGVO) and in the interest of a secure, fast and efficient provision of our online offer by a professional provider (Art. 6 para. 1 lit. f DSGVO).",
    "privacyNotice_hostingText3": "Our hoster will only process your data to the extent necessary to fulfill its service obligations and will follow our instructions regarding this data.    ",
    "privacyNotice_hostingText4": "We use the following hoster:",
    "privacyNotice_privacyText": "The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this privacy policy.",
    "privacyNotice_privacyText2": "When you use this website, various personal data are collected. Personal data is data with which you can be personally identified. This privacy policy explains what data we collect and what we use it for. It also explains how and for what purpose this is done.",
    "privacyNotice_privacyText3": "We point out that data transmission over the Internet (eg communication by e-mail) security gaps. A complete protection of the data against access by third parties is not possible.",
    "privacyNotice_responsibleText2": "The controller is the natural or legal person who alone or jointly with others determines the purposes and means of the processing of personal data (e.g. names, e-mail addresses, etc.).",
    "privacyNotice_storageText": "Unless a more specific storage period has been specified within this privacy policy, your personal data will remain with us until the purpose for data processing no longer applies. If you assert a legitimate request for deletion or revoke your consent to data processing, your data will be deleted unless we have other legally permissible reasons for storing your personal data (e.g. retention periods under tax or commercial law); in the latter case, the data will be deleted once these reasons no longer apply.",
    "privacyNotice_transferText": "Among other things, we use tools from companies based in the USA or other third countries that are not secure under data protection law. If these tools are active, your personal data may be transferred to these third countries and processed there. We would like to point out that no level of data protection comparable to that in the EU can be guaranteed in these countries. For example, US companies are obliged to hand over personal data to security authorities without you as a data subject being able to take legal action against this. It can therefore not be ruled out that US authorities (e.g. intelligence services) process, evaluate and permanently store your data located on US servers for monitoring purposes. We have no influence on these processing activities.",
    "privacyNotice_revocationText": "Many data processing operations are only possible with your express consent. You can revoke consent you have already given at any time. The legality of the data processing carried out until the revocation remains unaffected by the revocation.",
    "privacyNotice_rightToObjectText": "IF THE DATA PROCESSING IS CARRIED OUT ON THE BASIS OF ART. 6 ABS. 1 LIT. E OR F DSGVO, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA AT ANY TIME FOR REASONS ARISING FROM YOUR PARTICULAR SITUATION; THIS ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS. THE RESPECTIVE LEGAL BASIS ON WHICH PROCESSING IS BASED CAN BE FOUND IN THIS PRIVACY POLICY. IF YOU OBJECT, WE WILL NO LONGER PROCESS YOUR PERSONAL DATA UNLESS WE CAN DEMONSTRATE COMPELLING LEGITIMATE GROUNDS FOR THE PROCESSING WHICH OVERRIDE YOUR INTERESTS, RIGHTS AND FREEDOMS, OR THE PROCESSING SERVES THE PURPOSE OF ASSERTING, EXERCISING OR DEFENDING LEGAL CLAIMS (OBJECTION UNDER ARTICLE 21 (1) DSGVO).",
    // "section_intro_title":"Black Basement",
    "section_intro_subtitle": "Rock 'n' Roll from Hannover Germany",
    "section_intro_para_1": "After the foundation in 2020, the trio, consisting of Marc Dohmeyer (Vocals/Bass), Henning Fahß (Drums) and Dennis Glinke (Guitar/Vocals), invented their own genre without further ado...Bigfootrock! The up-and-coming musicians from Hanover not only combine creativity with the ever-present question \"ever thought about that?\" in their music, but also distinguish themselves above all through interlocking instrumentalism, fat guitar riffs and cranky themes.",
    "section_intro_para_2": "The band delivers contemporary free-spirited rock with that certain dash of conspiracy theory.\nBecause of this trio's unique vibe, listeners can expect an individual sound with intriguing storylines.",
    "section_intro_para_3": "In February, Black Basement released the first single \"7885\" and the second single \"Settle Down\" in April 2021. With regard to an, in late summer, following album, the listener can look forward to more singles during 2021 and cheer along.",
    "": "",
    "": "",
    "": "",
    "": "",
    "": ""
};
