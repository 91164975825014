import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Collapse, Button, Link, Typography, Container } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { LanguageContext } from '../../Context/';

import { SCROLL_REF_ID } from '../Main';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - ' + Number(theme.mixins.toolbar.minHeight + theme.spacing(3)) + 'px)'
    },
    [theme.breakpoints.up("sm")]: {
        container: {
            height: 'calc(100vh - ' + Number(theme.mixins.toolbar['@media (min-width:600px)'].minHeight + theme.spacing(3)) + 'px)'
        }
    },
    backButton: {
        alignSelf: 'baseline'
    }
}));

const ContainerCollapse = (props) => {
    const { children, openCollapse, onClose } = props;

    const classes = useStyles();
    const srollElement = useRef();
    const timeOut = useRef();
    const { translate } = useContext(LanguageContext);

    useEffect(() => {
        srollElement.current = window.document.getElementById(SCROLL_REF_ID);
    }, []);

    useEffect(() => {

        const handleScroll = ({ target }) => {
            if (!openCollapse) {

                return;
            }

            clearTimeout(timeOut.current);
            timeOut.current = setTimeout(() => {

                const elementHeight = target.lastChild.offsetHeight;
                const scrollHeight = target.scrollHeight;
                const scrollTop = target.scrollTop;

                if (scrollTop < scrollHeight - (2 * elementHeight)) {
                    onClose();
                }
            }, 200);
        };

        srollElement.current.addEventListener('scroll', handleScroll);

        return () => srollElement.current.removeEventListener('scroll', handleScroll);
    }, [openCollapse]);

    const timeoutSettings = {
        appear: 0,
        enter: 0,
        exit: 300
    };

    return (
        <Collapse in={openCollapse} timeout={timeoutSettings}>
            <Container className={classes.container}>
                <Button
                    className={classes.backButton}
                    startIcon={<ArrowBackIcon />}
                    onClick={onClose}
                >
                    {translate("back")}
                </Button>
                {children}
            </Container>
        </Collapse>
    );
};

export default ContainerCollapse;