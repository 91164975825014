import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';

import { useWidth } from '../../../Hooks/';

import Member from './Member';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    membersContainer: {
        marginTop: theme.spacing(4),
        display: 'flex',
        justifyContent: 'space-evenly'
    }
}));

const SectionId = "AboutUs";
const Title = "Über uns";
const MemberData = [
    {
        name: "Member 1",
        avatar: "https://material-ui.com/static/images/avatar/1.jpg",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam."
    },
    {
        name: "Member 2",
        avatar: "https://material-ui.com/static/images/avatar/2.jpg",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam."
    },
    {
        name: "Member 3",
        avatar: "https://material-ui.com/static/images/avatar/3.jpg",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam."
    }
];

const Members = () => {
    const classes = useStyles();
    const width = useWidth();

    const shouldDisableGutters = width === "lg" || width === "xl";

    return (
        <Container className={classes.container} id={SectionId} disableGutters={shouldDisableGutters}>
            <Typography variant="h4">{Title}</Typography>
            <Grid container/*  className={classes.membersContainer} */>
                {MemberData.map((member, index) => <Member key={index} {...member} />)}
            </Grid>
        </Container>
    );
};

export default Members;