import React, { useContext } from 'react';

import YouTubeIcon from '@material-ui/icons/YouTube';

import { ConfigContext } from '../../Context/';

import BaseButton from './BaseButton';

const YouTube = () => {
    const { socialMedia: { youTube } } = useContext(ConfigContext);

    return (
        <BaseButton url={youTube}>
            <YouTubeIcon />
        </BaseButton>
    );
};

export default YouTube;