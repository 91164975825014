import React from 'react';
import Slider from 'react-slick';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import { useWidth } from '../../../Hooks/';

// import CustomDot from './CustomDot';

const useStyles = makeStyles((theme) => ({
    imageSection: {
        margin: '25px 0',

        "& .slick-dots li button:before": {
            color: theme.palette.primary.main
        },
        "& .slick-dots li.slick-active button:before": {
            color: theme.palette.primary.main
        }
    },
    image: {
        maxWidth: '60%'
    }
}));

const SectionId = "Images";

const Images = [
    {
        src: "/Images/DSC06967.JPG"
    },
    {
        src: "/Images/DSC06995.JPG"
    },
    {
        src: "/Images/DSC07031.JPG"
    },
    {
        src: "/Images/DSC07058.JPG"
    },
    {
        src: "/Images/DSC07126.JPG"
    },
    {
        src: "/Images/DSC07151.JPG"
    },
    {
        src: "/Images/DSC07722.JPG"
    },
    {
        src: "/Images/DSC07732.JPG"
    },
    {
        src: "/Images/DSC07736.JPG"
    },
    {
        src: "/Images/DSC08126.JPG"
    },
    {
        src: "/Images/DSC08154.JPG"
    }
];

const ImageGallery = () => {
    const classes = useStyles();
    const width = useWidth();

    const settings = {
        // className: "center",
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        // centerMode: true,
        // centerPadding: "60px",
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const shouldDisableGutters = width === "lg" || width === "xl";

    return (
        <Container id={SectionId} disableGutters={shouldDisableGutters} className={classes.imageSection}>
            <Slider {...settings}>
                {Images.map(({ src }, index) => (
                    <div key={index}>
                        <img src={src} className={classes.image} />
                    </div>
                ))}
            </Slider>
        </Container>
    );
};

export default ImageGallery;