import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';

import WelcomeImg from '../../../Assets/welcome_.jpg';

import { Instagram, YouTube, Facebook, Spotify, AppleMusic, AmazonMusic } from '../../SocialMediaButtons/';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        minHeight: 'calc(100vh - 88px)', /* 'calc(100vh - 64px)', */
        // display: 'flex',
        // justifyContent: 'space-evenly'
    },
    heading: {
        position: 'absolute',
        top: '20%',
        left: '5%'
    },
    welcomeImgWrapper: {
        height: '100%'
        // display: 'flex',
        // justifyContent: 'center',
        // overflow: 'hidden',
        // maxHeight: '100vh'
    },
    welcomeImage: {
        // width: '100%',
        // width: 500,
        // maxHeight: '100%',
        // minWidth: 500

        maxWidth: '100%',
        maxHeight: '100%'
    },
    socialMediaContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        padding: ' 64px 0'
    },
    socialMediaButtonContainer: {
        padding: theme.spacing(3, 2)
    }
}));

const SectionId = "Welcome";

const WelcomeSection = () => {
    const classes = useStyles();

    return (
        <Container
            id={SectionId}
            className={classes.container}
            disableGutters
            maxWidth="md"
        >
            {/* <Typography className={classes.heading} variant="h3">BlackBasement</Typography> */}
            {/* <div className={classes.welcomeImgWrapper}>
            </div> */}
            <Grid
                container
                justify="space-evenly"
                alignItems="center"
            >
                <Grid item sm={7} md={8}>
                    <img className={classes.welcomeImage} src={WelcomeImg} />
                </Grid>
                <Grid item sm={5} md={4}>
                    <div className={classes.socialMediaButtonContainer}>
                        <Instagram />
                        <YouTube />
                        <Facebook />
                    </div>
                    <div className={classes.socialMediaButtonContainer}>
                        <Spotify />
                        <AppleMusic />
                        <AmazonMusic />
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default WelcomeSection;