import React, { useContext } from 'react';

import FacebookIcon from '@material-ui/icons/Facebook';

import { ConfigContext } from '../../Context/';

import BaseButton from './BaseButton';

const Facebook = () => {
    const { socialMedia: { facebook } } = useContext(ConfigContext);

    return (
        <BaseButton url={facebook}>
            <FacebookIcon />
        </BaseButton>
    );
};

export default Facebook;