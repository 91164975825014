import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    seperator: {
        height: theme.spacing(3),
        backgroundColor: theme.palette.primary.main
    }
}));

const Seperator = () => {
    const classes = useStyles();

    return (
        <div className={classes.seperator} />
    );
};

export default Seperator;