import React, { useContext } from 'react';

import InstagramIcon from '@material-ui/icons/Instagram';

import { ConfigContext } from '../../Context/';

import BaseButton from './BaseButton';

const Instagram = () => {
    const { socialMedia: { instagram } } = useContext(ConfigContext);

    return (
        <BaseButton url={instagram}>
            <InstagramIcon />
        </BaseButton>
    );
};

export default Instagram;