import React, { useEffect, useState } from 'react';
import '../Styles';

import { ThemeProvider } from '@material-ui/core/styles';

import { ConfigContext, NavigationApiContext } from '../Context/';
import LanguageContext, { DefaultLanguageContext, Translate } from '../Context/Language';

import GetInitialLanguage from '../Helper/GetInitialLanguage';

import Header from './Header/';
import Main from './Main';
import Theme from '../Styles/Themes/dark.js';

const GetLanguageApi = (selectedLanguage, setSelectedLanguage) => ({
    ...DefaultLanguageContext,
    selectedLanguage,
    setSelectedLanguage,
    translate: (key) => Translate(selectedLanguage, key)
});

const App = (props) => {
    const { config } = props;


    const initialLanguage = GetInitialLanguage(DefaultLanguageContext.availableLanguages);
    const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);
    const initialLanguageApi = GetLanguageApi(selectedLanguage, setSelectedLanguage);
    const [languageApi, setLanguageApi] = useState(initialLanguageApi);

    useEffect(() => {
        const newLanguageApi = GetLanguageApi(selectedLanguage, setSelectedLanguage);
        setLanguageApi(newLanguageApi);
    }, [selectedLanguage]);

    return (
        <ConfigContext.Provider value={config}>
            <NavigationApiContext.Provider value={{}}>
                <LanguageContext.Provider value={languageApi}>
                    <ThemeProvider theme={Theme}>
                        <Header />
                        <Main />
                    </ThemeProvider>
                </LanguageContext.Provider>
            </NavigationApiContext.Provider>
        </ConfigContext.Provider>
    );
};

export default App;