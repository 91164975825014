import React, { useContext } from 'react';

import { SvgIcon } from '@material-ui/core';

import SpotifyIcon from '../../Assets/CustomIcons/Spotify';

import { ConfigContext } from '../../Context/';

import BaseButton from './BaseButton';

const Spotify = () => {
    const { socialMedia: { spotify } } = useContext(ConfigContext);

    return (
        <BaseButton url={spotify}>
            <SvgIcon component={SpotifyIcon} viewBox="0 0 1792 1792" />
        </BaseButton>
    );
};

export default Spotify;