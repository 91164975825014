import React, { useContext } from 'react';

import { InputAdornment, MenuItem, TextField } from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';

import { LanguageContext } from '../Context';

const LanguageSelector = (props) => {
    const { ...others } = props;
    const languageApi = useContext(LanguageContext);

    const handleChange = ({ target: { value } }) => {
        languageApi.setSelectedLanguage(value);
    };

    return (
        <TextField
            select
            label="language"
            value={languageApi.selectedLanguage}
            onChange={handleChange}
            InputProps={{
                startAdornment: <InputAdornment position="start"><PublicIcon /></InputAdornment>,
            }}
            {...others}
        >
            {languageApi.availableLanguages.map((lng) => (
                <MenuItem key={lng} value={lng}>
                    {lng}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default LanguageSelector;