import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline } from '@material-ui/core';

import { WelcomeSection, Introduction/* , Members, Images */, VideoPlayer } from './Sections/';
import Footer from './Footer/';
import Seperator from './Seperator/';

import { useWidth } from '../Hooks/';

const SCROLL_REF_ID = "customBodyScrollRef";

const useStyles = makeStyles((theme) => ({
    customBody: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        overflowY: 'auto',
        overflowX: 'hidden',
        top: theme.mixins.toolbar.minHeight,
        width: '100%'
    },
    [theme.breakpoints.up("sm")]: {
        customBody: {
            top: theme.mixins.toolbar['@media (min-width:600px)'].minHeight
        }
    }
}));

const Main = () => {
    const classes = useStyles();
    const width = useWidth();

    const shouldDisableGutters = width !== "lg" && width !== "xl";

    return (
        <div id={SCROLL_REF_ID} className={classes.customBody}>
            <Container disableGutters={shouldDisableGutters}>
                <CssBaseline />
                <WelcomeSection />
                <Seperator />
                <Introduction />
                <Seperator />
                <VideoPlayer />
                {/* 
                    <Seperator />
                    <Introduction />
                    <Seperator />
                    <Images />
                    <Seperator />
                    <Members />
                    <Seperator />
                    <Images /> 
                */}
            </Container>
            <Seperator />
            <Seperator />
            <Footer />
        </div>
    );
};

export { SCROLL_REF_ID };
export default Main;