import React, { useContext, useState } from 'react';

import { Tab, Tabs } from '@material-ui/core';

import { LanguageContext } from '../../Context';

const LargeNav = (props) => {
    const { navData, onNavigate } = props;

    const [index, setIndex] = useState(0);

    const languageApi = useContext(LanguageContext);
    const { translate } = languageApi;

    const handleChange = (evt, newIndex) => {
        setIndex(newIndex);

        onNavigate(navData[newIndex].sectionId);
    };

    return (
        <Tabs value={index} onChange={handleChange}>
            {navData.map(({ label }, index) => (
                <Tab key={index} label={translate(label)} />
            ))}
        </Tabs>
    );
};

export default LargeNav;