import React, { useContext } from 'react';

import AppleMusicIcon from '@material-ui/icons/Apple';

import { ConfigContext } from '../../Context/';

import BaseButton from './BaseButton';

const AppleMusic = () => {
    const { socialMedia: { appleMusic } } = useContext(ConfigContext);

    return (
        <BaseButton url={appleMusic}>
            <AppleMusicIcon />
        </BaseButton>
    );
};

export default AppleMusic;