import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#F9DA2C",
            contrastText: "black"
        },
        secondary: {
            main: "#000",
            contrastText: "#F9DA2C"
        },
        background: {
            default: "black"
        }
    }
});