import React, { useContext } from 'react';

import { SvgIcon } from '@material-ui/core';

import AmazonMusicIcon from '../../Assets/CustomIcons/AmazonMusic';

import { ConfigContext } from '../../Context/';

import BaseButton from './BaseButton';

const AmazonMusic = () => {
    const { socialMedia: { amazonMusic } } = useContext(ConfigContext);

    return (
        <BaseButton url={amazonMusic}>
            <SvgIcon component={AmazonMusicIcon} />
        </BaseButton>
    );
};

export default AmazonMusic;