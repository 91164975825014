import { createContext } from 'react';

import TranslationRegister from '../Assets/Translation/';

const Translate = (lng, key) => {
    const keyRegister = TranslationRegister[lng];

    if (keyRegister && Object.prototype.hasOwnProperty.call(keyRegister, key)) {

        return keyRegister[key];
    }

    return key;
};

const DefaultLanguageContext = {
    selectedLanguage: "en-EN",
    availableLanguages: ["en-EN", "de-DE"],

    setSelectedLanguage: () => { },
    translate: key => key
};

export { DefaultLanguageContext, Translate };
export default createContext(DefaultLanguageContext);