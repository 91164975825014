import React from 'react';
import Slider from 'react-slick';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import { useWidth } from '../../../Hooks/';

import Video from './Video';

const useStyles = makeStyles((theme) => ({
    container: {
        // minHeight: 'calc(100vh - 88px)'
        // margin: theme.spacing(2)
    },
    slider: {
        margin: theme.spacing(0, 3)
    }
}));

const SectionId = "VideoPlayer";

const VideoData = [
    {
        "title": "Settle Down",
        "src": "https://videos.blackbasement.de/Settle Down.mov"
    },
    {
        "title": "Settle Down",
        "description": "Single release 9th April 2021\n\nBlack Basement on Settle Down, second single.",
        "isYoutube": true,
        "src": "https://www.youtube-nocookie.com/embed/DjuVu-KmBYs"
    },
    {
        "title": "7885",
        "src": "https://videos.blackbasement.de/7885.mov"
    },
    {
        "title": "7885",
        "isYoutube": true,
        "description": "Single release 1st February 2021.\n\nBlack Basement on 7885, first single.",
        "src": "https://www.youtube-nocookie.com/embed/HjfZndV0nBs"
    }
];

const GetSlidesToShowByWidth = (width) => {
    if (width === "sm" || width === "xs") {
        return 1;
    }

    if (width === "md") {
        return 2;
    }

    return 3;
};

const VideoPlayer = () => {
    const width = useWidth();
    const classes = useStyles();

    const shouldDisableGutters = width === "lg" || width === "xl";

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        // autoplay: true,
        // autoplaySpeed: 3000,
        speed: 500,
        slidesToShow: GetSlidesToShowByWidth(width),
        slidesToScroll: 1
    };

    return (
        <Container
            id={SectionId}
            className={classes.container}
            disableGutters={shouldDisableGutters}
        >
            <Slider {...settings} className={classes.slider}>
                {VideoData.map((data, index) => <Video key={index} {...data} />)}
            </Slider>
        </Container>
    );
};

export default VideoPlayer;