import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container } from '@material-ui/core';

import { LanguageContext } from '../../Context/';

const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1,
        overflow: 'auto'
    },
    title: {
        marginBottom: theme.spacing()
    },
    address: {
        whiteSpace: 'pre-line'
    },
    subtitle: {
        marginTop: theme.spacing(2)
    },
    paragraph: {
        marginBottom: theme.spacing()
    }
}));

const LegalNotice = () => {
    const { translate } = useContext(LanguageContext);
    const classes = useStyles();

    const title = translate("Privacy notice");

    const firstSubtitle = translate("General information");
    const generalText = translate("privacyNotice_generalText");

    const secondSubtitle = translate("Data collection on this website");
    const secondSubSubtitle = translate("Who is responsible for the data collection on this website?");
    const whoIsText = translate("privacyNotice_whoIsText");

    const thirdSubSubtitle = translate("How do we collect your data?");
    const howText1 = translate("privacyNotice_howText1");
    const howText2 = translate("privacyNotice_howText2");

    const fourthSubSubtitle = translate("What do we use your data for?");
    const whatIsText = translate("privacyNotice_whatText");

    const fithSubSubtitle = translate("What rights do you have regarding your data?");
    const rightsText = translate("privacyNotice_rightsText");
    const rightsText2 = translate("privacyNotice_rightsText2");

    const sixthSubtitle = translate("Hosting");
    const hostingText = translate("privacyNotice_hostingText");
    const hostingText2 = translate("privacyNotice_hostingText2");
    const hostingText3 = translate("privacyNotice_hostingText3");
    const hostingText4 = translate("privacyNotice_hostingText4");
    const hosterName = "1blu AG";
    const hosterAddress = "Riedemannweg 60\nD-13627 Berlin";

    const seventhSubtitle = translate("Mandatory information");
    const seventhSubSubtitle = translate("Privacy");
    const privacyText = translate("privacyNotice_privacyText");
    const privacyText2 = translate("privacyNotice_privacyText2");
    const privacyText3 = translate("privacyNotice_privacyText3");

    const eightthSubSubtitle = translate("Note on the responsible party");
    const responsibleText = translate("The responsible party for data processing on this website is:");
    const bandName = "BlackBasement GbR";
    const address = "Theodorstraße 9\n30159 Hannover";

    const responsibleCaption = translate("Represented by:");
    const name = "Marc Dohmeyer";
    const email = "info@blackbasement.de";
    const responsibleText2 = translate("privacyNotice_responsibleText2");

    const ninethSubSubtitle = translate("Storage duration");
    const storageText = translate("privacyNotice_storageText");

    const tenthSubSubtitle = translate("Note on data transfer to the USA and other third countries");
    const transferText = translate("privacyNotice_transferText");

    const eleventhSubSubtitle = translate("Revocation of your consent to data processing");
    const revocationText = translate("privacyNotice_revocationText");

    const twelvethSubSubtitle = translate("Right to object to data collection in special cases and to direct marketing (Art. 21 DSGVO)");
    const rightToObjectText = translate("privacyNotice_rightToObjectText");
    const rightToObjectText2 = translate("privacyNotice_rightToObjectText2");

    return (
        <Container className={classes.container} maxWidth="md">
            <div>
                <Typography className={classes.title} variant="h3">{title}</Typography>

                <Typography className={classes.subtitle} variant="h4">{firstSubtitle}</Typography>
                <Typography className={classes.paragraph}>{generalText}</Typography>

                <Typography className={classes.subtitle} variant="h4">{secondSubtitle}</Typography>
                <Typography className={classes.subtitle} variant="h5">{secondSubSubtitle}</Typography>
                <Typography className={classes.paragraph}>{whoIsText}</Typography>

                <Typography className={classes.subtitle} variant="h5">{thirdSubSubtitle}</Typography>
                <Typography className={classes.paragraph}>{howText1}</Typography>
                <Typography className={classes.paragraph}>{howText2}</Typography>

                <Typography className={classes.subtitle} variant="h5">{fourthSubSubtitle}</Typography>
                <Typography className={classes.paragraph}>{whatIsText}</Typography>

                <Typography className={classes.subtitle} variant="h5">{fithSubSubtitle}</Typography>
                <Typography className={classes.paragraph}>{rightsText}</Typography>
                <Typography className={classes.paragraph}>{rightsText2}</Typography>

                <Typography className={classes.subtitle} variant="h4">{sixthSubtitle}</Typography>
                <Typography className={classes.paragraph}>{hostingText}</Typography>
                <Typography className={classes.paragraph}>{hostingText2}</Typography>
                <Typography className={classes.paragraph}>{hostingText3}</Typography>
                <Typography className={classes.paragraph}>{hostingText4}</Typography>
                <Typography>{hosterName}</Typography>
                <Typography className={classes.address}>{hosterAddress}</Typography>

                <Typography className={classes.subtitle} variant="h4">{seventhSubtitle}</Typography>
                <Typography className={classes.subtitle} variant="h5">{seventhSubSubtitle}</Typography>
                <Typography className={classes.paragraph}>{privacyText}</Typography>
                <Typography className={classes.paragraph}>{privacyText2}</Typography>
                <Typography className={classes.paragraph}>{privacyText3}</Typography>

                <Typography className={classes.subtitle} variant="h5">{eightthSubSubtitle}</Typography>
                <Typography className={classes.paragraph}>{responsibleText}</Typography>
                <Typography>{bandName}</Typography>
                <Typography className={classes.address}>{address}</Typography>
                <Typography className={classes.subtitle}>{responsibleCaption}</Typography>
                <Typography>{name}</Typography>
                <Typography className={classes.paragraph}>{email}</Typography>
                <Typography className={classes.paragraph}>{responsibleText2}</Typography>

                <Typography className={classes.subtitle} variant="h5">{ninethSubSubtitle}</Typography>
                <Typography className={classes.paragraph}>{storageText}</Typography>

            </div>

            <Typography className={classes.subtitle} variant="h5">{tenthSubSubtitle}</Typography>
            <Typography className={classes.paragraph}>{transferText}</Typography>

            <Typography className={classes.subtitle} variant="h5">{eleventhSubSubtitle}</Typography>
            <Typography className={classes.paragraph}>{revocationText}</Typography>
        </Container>
    );
};

export default LegalNotice;